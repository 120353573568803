import React from "react";
import Head from "next/head";

type HelmetProps = {
  title: string;
  content: string;
};

const SEO = (props: HelmetProps) => {
  return (
    <Head>
      <link rel="icon" href="/favicon.ico" />
      <title>{props.title} - Flawnn 👩‍🎤👨‍🎤</title>
      <meta charSet="utf-8" />
      <meta
        name="description"
        property="og:title"
        content={props.content}
        key="Flawn, Flawn.dev, social media app, media, ui"
      />
      <meta name="keywords" content="flawn, flawns, flawn.dev"></meta>
      <meta
        name="twitter:description"
        content="Flawn 👨‍🎤 is a social media platforms apps for creator,
        developer, and more."
      />
      <meta
        name="twitter:title"
        content="Flawn 👨‍🎤 | Create your stories, and share them into the world"
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Head>
  );
};

export default SEO;
